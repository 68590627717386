@import url(https://fonts.googleapis.com/css?family=Ubuntu);
html {
  display: block;
  height: 100%; }

body {
  margin: 0;
  display: block;
  height: 100%; }

#root {
  margin: 0;
  display: block;
  height: 100%; }

.container-fluid {
  padding: 0; }

.main-container-fix {
  min-height: calc(100vh - 56px - 212px - 24px); }
  @media (min-width: 540px) and (max-width: 719px) {
    .main-container-fix {
      min-height: calc(100vh - 56px - 468px); } }

.footer {
  position: absolute; }

/* .app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
} */
/* 
.shadow-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.panel-title {
  text-align: center;
}

.panel-title h4 {
  font-weight: 600
}

.btn.btn-primary {
  background-color: #3f51b5;
}

.img-responsive {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
} */

.loading-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2BBBAD;
}
