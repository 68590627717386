@import url('https://fonts.googleapis.com/css?family=Ubuntu');

// using bootstrap container max widths from:
// https://getbootstrap.com/docs/4.0/layout/grid/#all-breakpoints
$phone-width: 540px;
$tablet-width: 720px;
$desktop-width: 992px;
$large-desktop-width: 1200px;

@mixin phone {
  @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$large-desktop-width - 1px}){
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}){
    @content;
  }
}

html {
  display: block;
  height: 100%;
}

body {
  margin: 0;
  display: block;
  height: 100%;
}

#root {
  margin: 0;
  display: block;
  height: 100%;
}

.container-fluid {
  padding: 0;
}

.main-container-fix {
  @include phone {
    min-height: calc(100vh - 56px - 468px);
  }
  min-height: calc(100vh - 56px - 212px - 24px);
}

.footer {
  position: absolute;
}

/* .app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
} */
/* 
.shadow-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.panel-title {
  text-align: center;
}

.panel-title h4 {
  font-weight: 600
}

.btn.btn-primary {
  background-color: #3f51b5;
}

.img-responsive {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
} */